import React, { useContext, useEffect } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalStates } from '../../GlobalStates';

const Toasty = ({ text = '', type = '', on = false }) => {
  const { alert, setAlert } = useContext(GlobalStates)
  const notify = () => toast(alert);
  useEffect(() => {
    if (alert) {
      console.log('eh')
      notify()
    }
    setAlert('')
  }, [alert])
  return (
    <div>
      <ToastContainer />
    </div>
  );
}
export default Toasty;