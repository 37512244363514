const configs = {
    linkaiAdmin: ['', 'Gyvunai', 'Kalendorius'],//nekeisti
    linkaiAdminText: ['Savininkai', 'Gyvūnai', 'Kalendorius'],
    linkaiNeAdmin: ['', 'Gyvunai', 'Kalendorius'],//nekeisti
    linkaiNeAdminText: ['Savininkai', 'Gyvūnai', 'Kalendorius'],
}

const basic = {
    "amziusLabel": "Amžius",
    "ExportTable": "lentelė",
    "arIstrintProfili": "Ar tikrai norite ištrinti įmonės profilį?",
    "filtruoti": "Filtruoti",
    "imones_label": "Įmonės",
    "imones_pav": "Įmonės pavadinimas",
    "issaugoti": "Išsaugoti",
    "iveskiteImone": "Įveskite įmonės pavadinimą",
    'iveskiteImoneMin': 'Neteisingas įmonės pavadinimas',
    "iveskitePass": "Slaptažodį turi sudaryti mažiausiai 6 simboliai",
    "ivestiName": "Įveskite vardą ir pavardę",
    "kopijuoti": "Kopijuoti",
    "kopijuoti_nuoroda": "Kopijuoti nuorodą",
    "laikaiLabel": "Kiek laiko dirba",
    "laukia_patvirtinimo": "Laukia patvirtinimo",
    "login_email": "El. paštas",
    "login_pass": "Slaptažodis",
    "login_pass_pakartot": "Pakartoti slaptažodį",
    "lytysLabel": "Lytis",
    "ne": "Ne",
    "nebegalioja": "Nebegalioja",
    "nepatvirtinta": "Nepatvirtinta",
    "neteisingasEmail": "Neteisingas el. paštas",
    "neteisingasTel": "Neteisingas tel. nr. (+...)",
    "neteisingiDuomenys": "Neteisingi prisijungimo duomenys",
    "new_login_pass": "Naujas slaptažodis",
    "padetysLabel": "Šeimyninė padėtis",
    "pamirsai_pass": "Pamiršai slaptažodį?",
    "pareigomsLabel": "Pareigos įmonėje",
    "patvirtinta": "Patvirtinta",
    "patvirtinti_reg": "Patvirtinti registraciją",
    "perziureti_rezultatus": "Paciento koretelė",
    "pildytojuTipaiLabel": "Pasirinkti",
    "prenumeratos_label": "Prenumeratos galiojimo data",
    "prisijungti": "Prisijungti",
    "prisiminti": "Priminti",
    "profilio_redagavimas": "Profilio redagavimas",
    "profilis": "Profilis",
    "redaguoti": "Redaguoti",
    "registruoti": "Registruoti",
    "registruotis": "Registruotis",
    "rodoma": "Rodoma:",
    "sekminga_reg": "Registracija sėkminga!",
    "sekminga_reg_txt1": "Jūsų profilį aktyvuosime arba su Jumis susisieksime per 3 darbo dienas. Kilus klausimams kviečiame mums parašyti el. paštu diversity@diversity.lt",
    "slaptazodzio_priminimas": "Slaptažodžio priminimas",
    "slaptazodzio_priminimas_txt": "Norėdami prisiminti slaptažodį, įveskite el. pašto adresą, kurį naudojate prisijungimui",
    "statusas_label": "Statusas",
    "sukurti_apklausa": "Skurti apklausą",
    "sukurti_imone": "Įmonės registracija",
    "susipazinau_su_taisyklem": "Susipažinau su privatumo politika ir taisyklėmis",
    "taip": "Taip",
    "telNr": "Tel. Nr.",
    "tipas_label": "Formos rūšis",
    "trinti": "Ištrinti",
    "priminimasIvyko": "Nuoroda slaptažodžio atkūrimui išsiųsta",
    "slaptazodisPakeistas": "Slaptažodis pakeistas",
    "accComfirmedError": "Vartotojas nepatvirtintas",
    "accValidToError": "Prenumeratos galiojimas baigėsi",

}

export const lt = {

    translations: {
        ...basic,
        ...configs,
        seniDarbai: 'Seni darbai',
        VisitDate:'Vizito data',
        priskirtiSeimininkaVartotojui: 'Priskiti šeimininką vartotojui',
        "vet-helper-into-text": "App",
        "types": ["Osteopatija", 'Kinezioteipavimas', 'Radijo dažnio bangų terapija', 'Veterinaro apsilankymas'],
        "typesLabel": "Gydymo tipas",
        Redaguoti_paciento_kortele: 'Šeimininko kortele',
        pacientai: 'Pacientai',
        'AnimalGender-Basic': ['Patelė', 'Patinas'],
        'AnimalGender-Horse': ['Kastratas', 'Eržilas', 'Kumelė'],
        'AnimalGender': {
            Horse: ['Kastratas', 'Eržilas', 'Kumelė'],
            Basic: ['Male', 'Female'],
        },
        Horse: 'Arklys',
        Gelding: 'Kumelė',
        AnimalTypes: ['Arklys', '-'],
        AnimalTypesLabel: "Gyvūno Tipas",
        animal_age: 'Amžius',
        animal_name: 'Vardas',
        AnimalGenderLabel: 'Lytis',
        owner: 'Savininkas',
        pacientas: 'Pacientas',
        //kalbos
        kalba_text: ['LT'],
        kalba: ['lt'],
        Nera_ats: '-',
        pp: 'Privatumo politika',
        pt: 'Paslaugų teikimo taisyklės',
        contacts: 'Contacts',
        email: 'Email: ',
        tel: 'Tel: ',
        adress: 'Adress: ',
        animals: 'Gyvūnai',
        Animal_Info: 'Animal info'

    }
}
