import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main:
                '#c9754f',
            secondary:
                '#6690a0',
            text: '#4e4b49',
        }
    },
    typography: {
        fontFamily: "Inter"
    }
})