import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";

//drawer elements used
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { Cookie } from "../clases/CookieClass";
import i18next, { t } from 'i18next';
import Modal from 'react-modal';
import LogoutIcon from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import { Link, useNavigate  } from "react-router-dom";




export default function MainNavigation(props) {

    /*
    react useState hook to save the current open/close state of the drawer,
    normally variables dissapear afte the function was executed
    */
    const cookie = new Cookie('Formu')
    const [open, setState] = useState(false);
    const [active, setActive] = useState('/')
    const navigate = useNavigate ();

    const atsijungti = () => {
        cookie.removeCookie()
        navigate('/');
    }
    const handleShow = () => setModalIsOpen(true);
    const [modalIsOpen, setModalIsOpen] = useState(false)


    /*
    function that is being called every time the drawer should open or close,
    the keys tab and shift are excluded so the user can focus between
    the elements with the keys
    */
    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        //changes the function state according to the value of open
        setState(open);
    };
    const closeMe = () => {
        setState(false);
    }
    return (


        <Container maxWidth="lg" >


            <IconButton
                edge="start"
                // color="inherit"
                aria-label="open drawer"
                onClick={() => { setState(true) }}
            // sx={{
            //     mr: 2,
            //     display: {
            //         xs: 'block',
            //         sm: 'none',
            //     }
            // }}
            >
                <MenuIcon />
            </IconButton>

            {/* The outside of the drawer */}
            <Drawer
                //from which side the drawer slides in
                anchor="left"
                //if open is true --> drawer is shown
                open={open}
                //function that is called when the drawer should close
                onClose={() => { setState(false) }}
            //function that is called when the drawer should open
            // onOpen={toggleDrawer(true)}
            >
                {/* The inside of the drawer */}
                <Box sx={{
                    p: 2,
                    height: 1,
                    backgroundColor: "#ffffff",
                    paddingRight: 10,
                }}>

                    <IconButton onClick={toggleDrawer(false)} sx={{ mb: 2 }}>
                        <CloseIcon />
                    </IconButton>
                    <Divider sx={{ mb: 2 }} />
                    <Box sx={{ mb: 2 }}>
                        <div className="row column" >
                            {props.element.props.children.map(e => {
                                return <Link key={e.key} onClick={() => { setState(false) }} to={e.props.to} className={e.props.className} >{e.props.children[0]}</Link>
                            })}
                        </div>
                    </Box>
                    <div className="row mobProfLog">
                        <Link key={'Profilis'} onClick={() => { setState(false) }} className={active == '/Profilis' ? 'active navItem column profilis' : 'navItem column profilis'} to="/Profilis">
                            <PersonIcon />
                            {t('profilis')}
                        </Link>
                        <a key={'atsijungti3'} className={active == '/' ? 'active navItem' : 'navItem'} onClick={() => { atsijungti() }}>
                            <LogoutIcon />
                        </a>
                    </div>
                </Box>
            </Drawer>

            <Modal
                isOpen={modalIsOpen}
                ariaHideApp={false}
                className="modal3"
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => { setModalIsOpen(false) }}
            >
                <div
                    className="centerContainer1 column">
                    <button className="closeBtn" onClick={() => {
                        setModalIsOpen(false)
                    }}>⨉</button>

                    <div className="modalInfo" >
                        <h4>{t('ar_tikrai_atsijungti')}?</h4>
                    </div>
                    <div>
                        <button
                            onClick={() => { atsijungti(); setModalIsOpen(false) }}
                            className=" btn primary btnWide">{t('taip')}</button>
                    </div>
                </div>
            </Modal>
        </Container>

    );
}