import React from "react"
import Button from '@mui/material/Button';
import { t } from 'i18next';


const PrimaryButton = (props) => {


    return (
        <Button
            id={props.id}
            variant={props.variant ? props.variant : 'contained'}
            sx={props.sx ? props.sx : {
                boxShadow: "1px 1px 5px #e7e7e7", ':hover': {

                    boxShadow: "1px 1px 5px lightgrey",
                }, maxWidth: "300px"
            }}
            disabled={props.disabled}
            onClick={(e) => { props.onClick(e) }}
            className={props.className}
            endIcon={props.endIcon}
            startIcon={props.startIcon}
            component={props.component}
            to={props.to}
            size={props.size}
        // style={{boxShadow: 1}}

        >
            {t(props.text)}

        </Button>
    )
}
export default PrimaryButton
