import React, { useState, createContext, useEffect, } from "react";
export const GlobalStates = createContext();

const TaskContextProvider = (props) => {
    const [tasks, setTasks] = useState('login');
    const [user, setUser] = useState('');
    const [token, setToken] = useState('');
    const [isLoading, setIsLoading] = useState(true)
    const [type, setType] = useState('')
    const [r, setR] = useState(0)
    const [alert, setAlert] = useState('')
    const [languageG, setLanguageG] = useState('')
    // const [path, setPath] = useState('http://localhost:1337/api/')//local`
    // const [pathImg, setPathImg] = useState('http://localhost:1337')//local for img
    const [path, setPath] = useState('https://vet-helper.herokuapp.com/api/')//global
    const [pathImg, setPathImg] = useState('https://vet-helper.herokuapp.com')//global for img

    const [gyvunai,setGyvunai] = useState([])
    const [savininkai,setSavininkai]=useState([])

    useEffect(()=>{
        if(user && user.darbas.length>0){
            setSavininkai(user.darbas)
            let arr =[]
            user.darbas.map(e=>{
                if(e.gyvunas.length>0){
                    arr.push(...e.gyvunas)
                }
            })
            setGyvunai(arr)
        }
    },[user])


    return (
        <GlobalStates.Provider value={{
            tasks, setTasks,
            user, setUser,
            token, setToken,
            path, setPath,
            pathImg, setPathImg,
            isLoading, setIsLoading,
            type, setType,
            alert, setAlert,
            r, setR,
            languageG, setLanguageG,
            gyvunai,setGyvunai,savininkai,setSavininkai
        }}>
            {props.children}
        </GlobalStates.Provider>
    );
};

export default TaskContextProvider

