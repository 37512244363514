export class Cookie {
    constructor(cname) {
        this.name = cname
    }

    getCookie() {
        let name = this.name + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split('; ');
        let returnValue = undefined;

        ca.forEach((cookie) => {
            if (cookie.indexOf(name) === 0) {
                returnValue = cookie.substring(name.length);
            }
        })

        return returnValue;
    }
    setCookie(token) {
        document.cookie = this.name + "=" + token + ";SameSite:Strict; Secure; signed: true; '";
    }
    removeCookie() {
        document.cookie = this.name + "=Doe; expires=Thu, 18 Dec 2013 12:00:00 UTC; path=/;SameSite:true; Secure; signed: true;";
    }
}
